import React from "react";

import Footer from "../components/Footer";
import Header from "../components/Header";
import ActionBar from "../components/ActionBar";

const LandingPage = () => {
  return (
    <>
      <div>
        <ActionBar />
        <Header />
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
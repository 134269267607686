import React, { useState } from "react";
import Modal from "./Modal";

const Header = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <div className="section_header">
        <div className="">
          <div className="container-large padding-vertical padding-xlarge">
            <div className="button-wrapper">
              <div
                className="button-large"
                onClick={() => setModalIsOpen(true)}
              >
                <div>RSVP (JÁ SOU VITALÍCIO)</div>
              </div>

              <a
                className="button-large"
                target="_blank"
                rel="noopener noreferrer"
                href="https://iug.li/p/m92TdEX0"
              >
                <div>QUERO VIRAR VITALÍCIO</div>
              </a>
            </div>
          </div>
        </div>
        
        <Modal 
          modalIsOpen={modalIsOpen} 
          setModalIsOpen={setModalIsOpen} 
        />
      </div>
    </>
  );
};

export default Header;

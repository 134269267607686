import React, { useState, useEffect } from "react";
import { intervalToDuration } from "date-fns";

const ActionBar = () => {
  const [duration, setDuration] = useState({});

  useEffect(() => {
    if (new Date("2024-04-20T17:59:59") >= new Date())
      setInterval(() => {
        setDuration(
          intervalToDuration({
            start: new Date(),
            end: new Date("2024-04-20T17:59:59"),
          })
        );
      }, 1000);
  }, []);

  return (
    <>
      <div className="stickbar-section">
        <div className="page-padding">
          <div className="text-size-large text-color-white">
            <span>
              ⏰ Vai ser em
              <span style={{ color: "#ffe500" }}>
                {`
                  ${duration.days || "0"}d 
                  ${duration.hours || "0"}h 
                  ${duration.minutes || "0"}m 
                  ${duration.seconds || "00"}s
                `}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionBar;

import React from "react";

const Message = ({ text, success }) => {
  return (
    <>
      <div className="message-container" style={{backgroundColor: success ? "#68ffbf" : "#f8d7db"}}>
        <span className="message-text" style={{color: success ? "#126c46" : "#842029"}}>{text}</span>
      </div>
    </>
  );
};

export default Message;

// #842029
// #f8d7db
// #68ffbf
// #126c46
import React, { useState } from "react";

import LoadingGif from "../images/loading-button.gif";
import CloseIcon from "../images/close-outline-1.svg";

import Message from "./Message";

const Modal = ({ modalIsOpen, setModalIsOpen }) => {
  const initialValue = {
    name: "",
    email: "",
  };
  const [values, setValues] = useState(initialValue);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [success, setSuccess] = useState(false);
  const [visible, setVisible] = useState(false);
  const onChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "name") {
      setName(value);
    }

    if (name === "email") {
      setEmail(value);
    }

    setValues({ ...values, [name]: value });
  };

  const handleClick = () => {
    setModalIsOpen(false);
    setVisible(false);
    setText("");
    setEmail("");
    setName("");
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let body = {
      name: values.name,
      email: values.email,
      what: "HALVEILON",
    };

    try {
      const response = await fetch(
        "https://api.paradigma.education/api/v2/events/assign",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...body }),
        },
      );

      if (response.status === 201) {
        setLoading(false);
        setVisible(true);
        setSuccess(true);
        setText(
          "Presença confirmada! ✅ Você receberá endereço e horários por e-mail.",
        );
      } else {
        setLoading(false);
        setVisible(true);
        setSuccess(false);
        setText("Você precisa ser Paradigma Vitalício pra participar 👀");
      }
    } catch (error) {
      setLoading(false);
      setVisible(true);
      setSuccess(false);
      setText("Você precisa ser Paradigma Vitalício pra participar 👀");
    }

    setLoading(false);
    console.log(values);
    setSubmitDisabled(false);
  };

  return (
    <>
      {modalIsOpen && (
        <div className="modal_section">
          <div className="modal">
            <button className="modal-close" onClick={handleClick}>
              <img src={CloseIcon} alt="icon" />
            </button>
            {visible &&
              (success ? (
                <Message text={text} success />
              ) : (
                <Message text={text} />
              ))}
            <div className="modal-content">
              <form
                id="email-form"
                className="form"
                autoComplete="off"
                onSubmit={onSubmit}
              >
                <div className="input-group" style={{ marginBottom: ".75rem" }}>
                  <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                    Nome completo
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={onChange}
                    value={name}
                    placeholder="Nome e sobrenome"
                    className="text-field w-input"
                  />
                </div>

                <div className="input-group" style={{ marginBottom: ".75rem" }}>
                  <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                    E-mail
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={onChange}
                    value={email}
                    placeholder="exemplo@exemplo.com"
                    className="text-field w-input"
                  />
                </div>

                <button
                  className="button-modal margin-top margin-xsmall w-inline-block"
                  type="submit"
                  disabled={submitDisabled}
                >
                  {!loading ? (
                    <span>confirmar presença</span>
                  ) : (
                    <div className="loader">
                      <img
                        style={{ width: "45px" }}
                        src={LoadingGif}
                        alt="loading..."
                      />
                    </div>
                    // <div>carregando...</div>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
